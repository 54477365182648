import React from "react";
import TrafficMessage from "../../../../SharedComponents/TrafficMessage/TrafficMessage";
import { useSelector } from "react-redux";
import GraphsToolTip from "../../../../SharedComponents/GraphsToolTip/GraphsToolTip";

const AffiliateTable = () => {
	const affiliatesData = useSelector(state => state.sessionAffiliates.data);
	const headers = ["Affiliate", "Total Humans", "Total Bots", "Bot Percentage"];
	const formatData = () => {
		var result = [];
		let id = 0;
		const uniqueAffiliates = [...new Set(Object.keys(affiliatesData.bot).concat(Object.keys(affiliatesData.human)))];

		uniqueAffiliates.forEach(affiliate => {
			const botCount = affiliatesData.bot[affiliate] || 0;
			const humanCount = affiliatesData.human[affiliate] || 0;
			const existing = result.find(obj => obj.affiliate === affiliate);

			if (existing) {
				existing.total_Bots += botCount;
				existing.total_Humans += humanCount;
			} else {
				if (affiliate !== "No Affiliate") {
					result.push(
						{ id, affiliate, total_Bots: botCount, total_Humans: humanCount, bot_percentage: ((botCount / (botCount + humanCount)) * 100).toFixed(1) }
					);
					id++;
				}
			}
		});

		result = result.sort(({ bot_percentage: a }, { bot_percentage: b }) => b - a);
		return result;
	}

	const formattedData = formatData();

	return (
		<div className="affiliate-table-container-parent">
			{formattedData.length !== 0 ?
				<div className="affiliates-graph-header">
					<GraphsToolTip message={"A table showing the amount of bots and humans from each affiliate source"} />
				</div> :
				<></>
			}
			<div className="affiliate-table-container">
				<div className="table" style={{ background: "white", width: "96.8%" }}>
					{formattedData.length !== 0 ?
						<>
							<div className="lheader table-row affiliates" style={{ height: "2vh" }}>
								{headers.map((items) =>
									<div className="cell affiliates">{items}</div>
								)
								}
							</div>
							<div className="tbody" style={{ whiteSpace: "nowrap" }}>
								{
									formattedData?.map((items) =>
										<div className="table-row" key={items.id}>
											<div className="cell affiliates affiliate-name" title={items.affiliate}>{items.affiliate}</div>
											<div className="cell affiliates" title={items.total_Humans}>{items.total_Humans}</div>
											<div className="cell affiliates" title={items.total_Bots}>{items.total_Bots}</div>
											<div className="cell affiliates" title={items.bot_Percentage}>{items.bot_percentage}%</div>
										</div>
									)
								}
							</div>
						</> :
						<TrafficMessage message="There are no bots for this time Period." />
					}
				</div>
			</div>
		</div>
	)
}

export default AffiliateTable;