import React from "react";
import "./MFAModal.scss";
import api from "../../../../../veracityapi";


const MFAModal = (props) => {

	const [QR, setQR] = React.useState(false);
	const [MFA, setMFA] = React.useState("");
	const [displayLoading, setDisplayLoading] = React.useState(true);
	const [errorState, setErrorState] = React.useState(false);
	const userGUID = localStorage.getItem("userGUID");
	const input = document.querySelector('input[name="mfainput"]');

	if(input){
		input.addEventListener('invalid', (e) =>{
			if(e.type == 'invalid'){
				e.target.setCustomValidity('Please enter the six digit number from your authenticator.')
			}		
		});
		input.addEventListener('change', function (event) {
			event.target.setCustomValidity('');
		})
	};

	React.useEffect(() => {
		api.GET(`/user/${userGUID}/mfa`).then(res=>{
			setQR(res.item.QRcode);
			setDisplayLoading(false);
		}).catch(err =>{			
			console.log("ERROR: ", err);
			props.setMFAModal(false);
		});
	},[]);


	const handleFormSubmit = (e) => {
		e.preventDefault();
		setDisplayLoading(true);
		api.POST(`/user/${userGUID}/mfa`,{code: +MFA},{}).then(res=>{
			setQR("complete");
			localStorage.setItem("mfeEnabled", true);
			props.setMFAActive(true);
			setDisplayLoading(false);
		}).catch(err => {
			setDisplayLoading(false);
			setErrorState(true);
			setTimeout(()=>{ setErrorState(false)},3500)
			setMFA("");			
			// console.log("LOG ERROR: ", err)
		});
	};

	return (
		<>
			{props.mfaModal &&
				<div className="mfa-modal-section">
					
					<div className="mfa-modal-content">
					
						<div className="mfa-modal-header">
						
							<h3 className="modal-name">MFA</h3>
							<h3 className="close-modal" onClick={() => props.setMFAModal(false)}>X</h3>
						</div>
						{ errorState ? <div className="mfa-modal-error-display"><span>Code incorrect please try again.</span></div> : ""}
						<div className="mfa-qr">
							<p >{ QR === "complete" ?  "Next login will require MFA Authentication." : "Scan the QR code to get your secure one time MFA code."}</p>
							<div className="mfa-setup-image-container">
								{
									displayLoading ? <img width="152px" src={require("../../../../../components/images/Rolling-1s-200px (2).gif")} alt="Loading" />
									: QR === "complete"? <span >Completed.</span> : <img width="152px" src={QR} alt="test" />									
								}
							</div>
							<form
                            	className="mfa-setup-form"
                            	onSubmit={handleFormSubmit}
                        	>
							<input
								className="mfa-setup-input"
								placeholder="Please enter your 6 digit MFA code"
								type="text"
								name="mfainput"
								value={MFA}
								onChange={(e) => setMFA(e.target.value)}
								inputMode="numeric"
								minLength={6}
								maxLength={6}
								pattern="[0-9]{6}"
								readOnly={displayLoading}
								style={ QR === 'complete' ? {visibility:'hidden'} : {}}
								required								
							/>
							<input 
								className="mfa-setup-button" 
								type="submit"
								readOnly={displayLoading}
								style={ QR === 'complete' ? {visibility:'hidden'} : {}}
							/>
							</form>
						</div>
					</div>
				</div>}
		</>
	);
};

export default MFAModal;