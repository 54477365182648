
import React from "react";
import './LineGraph.scss';
import { ResponsiveLine } from "@nivo/line";
import GraphsToolTip from "SharedComponents/GraphsToolTip/GraphsToolTip";
import { utcTimeStringToLocalTimeString } from "SharedComponents/DateManager";
import Loader from "SharedComponents/Loader/Loader";
import TrafficMessage from "SharedComponents/TrafficMessage/TrafficMessage";
import { MaxIcon } from "SVG/MaxIcon";
import GraphModal from "SharedComponents/GraphModal/GraphModal";
import { formatNumber } from "Utils/utils";

const LineGraph = (props) => {
	const [initialLinegraphState, setinitialLinegraphState] = React.useState({});
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [maxIcon, setMaxIcon] = React.useState(false);
	const handleMaximizeClick = () => {
		setIsModalOpen(!isModalOpen);
	};

	function formatData(data) {
		let humanarray = [];
		let botsarray = [];
		for (let keys in data) {
			let humans = data[keys].human + data[keys].ok + data[keys].monitored;
			let bots = data[keys].bot;
			humanarray.push({
				x: utcTimeStringToLocalTimeString(keys, "", "Do MMM"),
				y: humans
			});
			botsarray.push({
				x: utcTimeStringToLocalTimeString(keys, "", "Do MMM"),
				y: bots
			});
		}
		return [humanarray, botsarray]
	};

	function sortData(sessionBreakdownData) {
		let copiedData = JSON.parse(JSON.stringify(sessionBreakdownData));

		for (let date in copiedData) {
			let entries = Object.entries(copiedData[date]);
			entries.sort((a, b) => b[1] - a[1]);
			copiedData[date] = Object.fromEntries(entries);
		}
		return copiedData;
	}

	let sortedData = sortData(props?.sessionBreakdownData || []);

	const formattedData = formatData(sortedData);

	const CustomSymbolShape = ({ x, y, size, fill, borderWidth, borderColor }) => (
		<rect
			x={x}
			y={y}
			fill={fill}
			strokeWidth={borderWidth}
			stroke={borderColor}
			width={size}
			height={size}
			style={{ width: "28px", height: "2px", display: "flex", alignItems: "center" }}
		/>
	);

	const calculateHumanTraffic = (initialLinegraphState) => {
		let value = (100 - (parseInt(initialLinegraphState[props.botsString]) / (parseInt(initialLinegraphState.Humans) + parseInt(initialLinegraphState[props.botsString]))) * 100).toFixed(1);
		return isNaN(value) ? " 0 %" : " " + value + "%"
	};

	function toolTipContent({ point }) {
		setinitialLinegraphState({
			x: point.data.x,
			[props.botsString]: (formattedData[1].find(item => item.x === point.data.x)).y ?? 0,
			Humans: (formattedData[0].find(item => item.x === point.data.x)).y ?? 0
		});

		return (
			<div className="toolTipContent">
				<b>{initialLinegraphState.x}</b>
				<div>Humans: <b className="humansValues">{formatNumber(initialLinegraphState.Humans)}</b> </div>
				<div>{props.botsString}: <b className="botsValues">{formatNumber(initialLinegraphState[props.botsString])}</b></div>
				<div>Human Traffic:
					<span style={{ fontWeight: "700" }}>
						{calculateHumanTraffic(initialLinegraphState)}
					</span>
				</div>
			</div>
		)
	};

	const SessionBreakdownGraph = React.useMemo(() => {
		let SessionBreakdownData = {
			data: [
				{ id: [props.botsString], data: formattedData[1] },
				{ id: "Humans", data: formattedData[0] }],
		}

		let colours = [
			"rgba(226, 41, 55, 1)",
			"rgba(102, 45, 145, 1)"
		];

		if (props.total !== 0) {
			return (
				<ResponsiveLine
					data={SessionBreakdownData.data}
					isInteractive={true}
					margin={{ top: 30, right: 35, bottom: 85, left: 50 }}
					xScale={{ type: 'point' }}
					yScale={{
						type: 'linear',
						min: '0',
						max: 'auto',
						stacked: false,
						reverse: false
					}}

					curve="monotoneX"
					tooltip={toolTipContent}
					colors={colours}
					yFormat=" >-.2f"
					axisTop={null}
					axisRight={null}
					axisBottom={{
						// orient: 'bottom',
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 45,
						legend: '',
						legendOffset: 36,
						legendPosition: 'middle',

					}}
					axisLeft={{
						orient: 'left',
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: '',
						legendOffset: -40,
						legendPosition: 'middle',
						format: (value) => value % 2 === 0 ? value : ""
					}}

					pointSize={10}
					pointColor={{ theme: 'background' }}
					pointBorderWidth={2}
					pointBorderColor={{ from: 'serieColor' }}
					pointLabelYOffset={-12}
					useMesh={true}
				/>
			);
		} else {
			return (
				<TrafficMessage message="There has been no traffic in the selected time period." />
			)
		}
	}, [formattedData]);

	return (
		<div className="linegraph-container" onMouseEnter={() => setMaxIcon(true)} onMouseLeave={() => setMaxIcon(false)}>
			<div className="graph-header">
				<div className="graph-heading">Daily Sessions</div>
				<GraphsToolTip message={props.tooltip} />
				{maxIcon &&
					<div className="max-icon" onClick={handleMaximizeClick} title="Expand Graph">
						<MaxIcon />
					</div>
				}
			</div>
			{props.loading ? <Loader /> : SessionBreakdownGraph}
			{isModalOpen &&
				<GraphModal
					isOpen={isModalOpen}
					onClose={handleMaximizeClick}
					graph={SessionBreakdownGraph}
					title="Daily Sessions"
				/>
			}
		</div>
	);
};

export default LineGraph;
