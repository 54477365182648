import api from "../../../../veracityapi";
import { finishedSessions } from "../../../../Redux/actions";
import { getStartTimeStampFromString, getComparisonTimeStampFromString, getEndTimeStampFromString } from "../../../../SharedComponents/DateManager";


export default function Data(params, dispatch){	
	const websiteGUID = localStorage.getItem('selected_site');
	const normalStart = getStartTimeStampFromString(params.timeRange);
	const comparisonStart =  getComparisonTimeStampFromString(params.timeRange);
	const endTimeStamp = getEndTimeStampFromString(params.timeRange);
	
	const getFinishedSessionsData = new Promise((resolve, reject) => {
		api.GET(`/finishedSessionSummary/${websiteGUID}/${normalStart}/${endTimeStamp}`).then(res => {
			resolve({
				currentData: res.item.statusCounts
			});		
		}).catch((error) => {
			api.handleError(error);
		});
	});

	const getSessionBreakdown= new Promise((resolve, reject)=>{
		api.GET(`/analytics/session-breakdown/${websiteGUID}/${normalStart}/${endTimeStamp}/0/all`)
		.then(res=>{
			resolve({
				breakDownData: res.item
			});
		}).catch((error)=>{
			api.handleError(error);
		});
	});

	const getComparisonData = new Promise((resolve, reject) => {	
		api.GET(`/finishedSessionSummary/${websiteGUID}/${comparisonStart}/${endTimeStamp}`)
		.then(res => {
			resolve ({
				comparasonData: res.item.statusCounts
			});
		}).catch((error) => {
			api.handleError(error);
		});
	});


	return new Promise((resolve, reject) =>{
		Promise.all([getFinishedSessionsData, getSessionBreakdown, getComparisonData]).then(async (values)=>{
			const temp = {
				timeRange: params.timeRange,	
			};
			for(let obj of values ){
				Object.assign(temp, obj);
			};
			dispatch(finishedSessions(temp));
			resolve(true);
		});
	});	
};