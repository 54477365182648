import React from "react";
import "./FinishedSessionsPanel.scss";
import HumanPercentage from "./FinishedSessionsComponents/HumansPercentage/HumanPercentage";
import LineGraph from "./FinishedSessionsComponents/FinisihedSessionsLineGraph/LineGraph";
import BotsPercentage from "./FinishedSessionsComponents/BotsPercentage/BotsPercentage";
import { useSelector} from "react-redux";
import { store } from "../../../../Redux/store";

const FinishedSessionsPanel = (props) => {

    const finishedSessionsData = useSelector(state => state?.finishedSessions?.data||[]);
    const websiteStatus = store.getState().websiteDetails?.data?.websiteDetails?.status;
    const botsString = websiteStatus === "protect" ? "Blocked Bots" : "Detected Bots";
    const { human, monitored, ok, bot } = finishedSessionsData?.currentData??[];
    const total = human + monitored + ok + bot;
    const humansValue = human + monitored + ok;
    const humanPercentage = getPercent(human + monitored + ok);
    const botPercentage = getPercent(bot);

    function getPercent(inp) {
        const value = ((inp / total) * 100).toFixed(1)
        return isNaN(value) ? 0 : value;
    };

    return (
        <div className="finishedSessions-graph-area">
            <LineGraph
                sessionBreakdownData={finishedSessionsData?.breakDownData}
                botsString={botsString}
                loading={props.loading}
                total={total}
                tooltip={"A daily summary based on the number of humans and malicious bots on your website. This data excludes good bots."}
            />
            <HumanPercentage
                humansValue={humansValue}
                humanPercentage={humanPercentage}
                comparisonData={finishedSessionsData?.comparasonData}
                botsString={botsString}
                loading={props.loading}
                total={total}
            />
            <BotsPercentage bots={bot}
                botPercentage={botPercentage}
                comparisonData={finishedSessionsData?.comparasonData}
                botsString={botsString}
                loading={props.loading}
                total={total}
            />
        </div>
    );
};

export default FinishedSessionsPanel;
