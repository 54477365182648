import React from "react";
import "./Table.scss";
import { formatNumber } from "../../../Utils/utils";
import { Link, useParams } from 'react-router-dom';

const Table = ({ keys, values, total, baseURL, adType }) => {
	const currencyFields = ['spend', 'wasted_spend', 'savings', 'human_click_cost'];
	const percentageFields = ['bot_percentage'];
	const currenySymbol = localStorage?.getItem("bcn-currency-symbol");
	const stringFields = ['campaign_name', 'link_notes'];
	const getFormatedValue = (fieldName, value) => {
		let retValue = value ?? 0;
		if (typeof value === "number" && currencyFields.includes(fieldName)) {
			retValue = `${currenySymbol} ${formatNumber(value.toFixed(2))}`
		} else if (percentageFields.includes(fieldName)) {
			retValue = `${value.toFixed(1)}%`
		} else if (!stringFields.includes(fieldName)) {
			retValue = formatNumber(value);
		}
		return retValue;
	}

	const { campaignId, linkId } = useParams();
	let valuesToRemove = ["channel_id", "channel_name", "link_id", "campaign_id", 'leads', "original_url", "wasted_spend", "ad_channels", "bots", "engaged", "savings"];
	if (!!campaignId) {
		valuesToRemove.push('campaign_name');
	}
	let filteredKeys = keys.filter((element) => !valuesToRemove.includes(element));

	return (
		<div className="table">
			{filteredKeys.length &&
				<div className="lheader table-row">
					{filteredKeys.map((i, j) =>
						<span key={j} className="cell">{i.replace(/_/gi, ' ').replace(/percentage/g, '%').replace(/visits/g, 'Human Visitors')}</span>
					)}
				</div>
			}
			<div className="tbody">
				{values && values.map((row, j) => {
					return (
						<>
							{campaignId === undefined || linkId === undefined ?
								<Link key={j} to={`${baseURL}/${adType}/${row && (row['link_id'] ?? row['campaign_id'])}`} className="table-row" >
									{filteredKeys.map((i, j) => {
										return (<span key={j} className="cell" style={{ color: "#662D91" }}>
											{getFormatedValue(i, row[i])}
										</span>
										)
									}
									)
									}
								</Link> :
								<div className="table-row">{filteredKeys.map((i, j) => {
									return (<span key={j} className="cell">
										{getFormatedValue(i, row[i])}
									</span>
									)
								}
								)
								}
								</div>
							}
						</>
					)
				}

				)}
				<div className="table-row total">
					{filteredKeys.map((i, j) =>
						<span key={j} className="cell">
							{getFormatedValue(i, total[i])}
						</span>
					)
					}
				</div>
			</div>
		</div>
	)
}
export default Table;
